import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Berechtigungen",
  "description": "So können Sie die Datenschutzbestimmungen der App anpassen.",
  "author": "Jochen Ritscher",
  "categories": ["settings"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Berechtigungsstufen`}</p>
    <ul>
      <li parentName="ul">{`Administrator`}</li>
      <li parentName="ul">{`Auditor`}</li>
      <li parentName="ul">{`Datenschutzbeauftragter`}</li>
      <li parentName="ul">{`Manager`}</li>
      <li parentName="ul">{`Recruiter`}</li>
      <li parentName="ul">{`Hiring Manager / (Einstellender) Vorgesetzter `}</li>
      <li parentName="ul">{`Bewerber`}</li>
    </ul>
    <h2>{`Bewerber`}</h2>
    <p>{`Darf Bewerbungen erstellen sowie die eigene Bewerbung bearbeiten und löschen.`}</p>
    <h2>{`Hiring Manager`}</h2>
    <p>{`Darf die Bewerber sehen,  dessen Stellen er/sie zugewiesen ist.`}</p>
    <h2>{`Recruiter`}</h2>
    <p>{`Darf im Standard alle der Rolle zugewiesenen Bewerberdaten einsehen.`}</p>
    <h2>{`Manager`}</h2>
    <p>{`Darf im Standard alle Bewerberdaten einsehen.`}</p>
    <h2>{`Admininistrator`}</h2>
    <p>{`Darf Einstellungen am System vornehmen`}</p>
    <h2>{`Datenschutzbeauftragter`}</h2>
    <p>{`Darf Daten lesen, löschen und die Löschung überwachen`}</p>
    <h2>{`Auditor`}</h2>
    <p>{`Darf Daten lesen (z.B. Betriebsrat)`}</p>
    <h2>{`Wofür können Gruppen verwendet werden?`}</h2>
    <p>{`Gruppen können verwendet werden um kriterienbasierte Rechte zu vergeben. Dadurch lassen sich die Standardrollen erweitern.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      